import request from '@/until/request.js'
const prefixName = 'base/role-custom'
/** 获取用户列表 */
export function getRoleList(data) {
    return request({
      url: prefixName,
      method: 'get',
      params: { ...data }
    })
  }
  export function search(searchValue = {}, pageNum, pageSize) {
    return request({
      url: prefixName,
      method: 'get',
      params: { ...searchValue, pageNum, pageSize }
    })
  }
  
  export function check(name) {
    return request({
      url: prefixName + '/name/' + name,
      method: 'get'
    })
  }
  
  export function getById(id) {
    return request({
      url: prefixName + '/' + id,
      method: 'get'
    })
  }
  
  export function saveRole(result) {
    return request({
      url: prefixName,
      method: 'post',
      data: result
    })
  }
  export function GetAuthorized(id){
    return request({
        url: prefixName + '/authorized/' + id,
        method: 'get'
      })
  }
  
  export function EditAuthorized(result) {
    return request({
      url: prefixName + '/authorized',
      method: 'put',
      data: result
    })
  }

  export function EditRole(result) {
    return request({
      url: prefixName,
      method: 'put',
      data: result
    })
  }
  
  export function deleteRole(id) {
    return request({
      url: prefixName + '/' + id,
      method: 'delete'
    })
  }
  export function changeEnAble(id, status) {
    return request({
      url: prefixName + '/status/' + status,
      method: 'put',
      data: { id: id }
    })
  }
  export function checkPhone(phone) {
    // /base/user/phone/{phone}/officeId/{officeId}
    return request({
      url: prefixName + '/phone/' + phone,
      method: 'get'
    })
  }
  
  export function getMenus(pageNum, pageSize){
    return request({
      url: '/base/menus',
      method: 'get',
      params: { pageNum, pageSize }
    })
  }
  